<template>
  <v-dialog
    v-model="services.showCreateFather"
    width="500"
    @click:outside="$emit('set-auto-fill')"
  >
    <v-card>
      <v-card-title v-if="!isEditing || isAutoFill">
        Create Father Node
      </v-card-title>
      <v-card-title v-else>
        Edit Father Node
      </v-card-title>

      <v-card-text v-if="isAutoFill">
        Fill with:
        <v-select
          v-model="selectedFillType"
          :items="fillTypes"
          dense
          chips
          multiple
          clearable
          deletable-chips
        />
        <v-text-field
          v-model="selectedYear"
          label="Year"
          :disabled="disableYear"
          :rules="name_rules"
          :counter="4"
        />
      </v-card-text>
      <v-card-text v-else>
        <v-form
          ref="fatherForm"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="fatherForm.node_name"
            label="Father Node Name"
            required
            :rules="name_rules"
            :counter="255"
          />
          <v-text-field
            v-model="fatherForm.node_description"
            label="Father Node Description"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="isAutoFill"
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          @click="generateChildren"
        >
          Create Father Node
        </v-btn>
        <v-btn
          v-else-if="!isEditing"
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          :disabled="checkForm()"
          @click="createFather()"
        >
          Create Father Node
        </v-btn>
        <v-btn
          v-else
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          :disabled="checkForm()"
          @click="editFather()"
        >
          Edit Father Node
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, manageLenghtError, getRegExp } from '@/formRules.js';
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-business-days';

export default {
  props: {
    fatherForm: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isTranslating: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    isAutoFill: {
      type: Boolean,
      default: false
    },
    routeType: {
      type: String,
      default: null
    },
    selectedLanguage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      name_rules: [...required, ...manageLenghtError(255)],
      fillTypes: ['Regions', 'Year', 'Months'],
      selectedFillType: ['Regions', 'Year', 'Months'],
      selectedYear: moment().format('YYYY'),
      number_rules: [...getRegExp('numbers_only')],
      valid: true
    };
  },
  computed: {
    ...mapState(['services', 'ticketing']),
    disableYear() {
      return !this.selectedFillType.includes('Year');
    }
  },
  methods: {
    checkForm() {
      return !(this.fatherForm.node_name !== '' && this.valid);
    },
    createFather() {
      const id = uuidv4();
      const service_id = JSON.parse(JSON.stringify(this.services.service_id));

      const objToPush = {
        service_id: service_id,
        node_name: this.fatherForm.node_name,
        node_description: this.fatherForm.node_description,
        node_id: id,
        father_id: '',
        children: [],
        sort_id: ''
      };

      if (this.routeType === 'REPORT-HUB') {
        objToPush.crm_folder = this.viewMode;
      }

      if (!this.ticketing.isProfiling) {
        objToPush.sort_id = ++this.services.storeFatherSort_id;
      } else {
        objToPush.sort_id = 1;
      }

      this.services.hierarchyItems.push(objToPush);
      this.services.showCreateFather = false;
    },
    editFather() {
      this.services.hierarchyItems.forEach(father => {
        if (father.node_id == this.fatherForm.node_id) {
          father.node_name = this.fatherForm.node_name;
          father.node_description = this.fatherForm.node_description;
        }
      });
      this.services.showCreateFather = false;
    },
    async generateChildren() {
      const italianRegions = [
        'Abruzzo', 'Basilicata', 'Calabria', 'Campania', 'Emilia-Romagna',
        'Friuli-Venezia Giulia', 'Lazio', 'Liguria', 'Lombardia',
        'Marche', 'Molise', 'Piemonte', 'Puglia', 'Sardegna',
        'Sicilia', 'Toscana', 'Trentino-Alto Adige', 'Umbria',
        'Valle d\'Aosta', 'Veneto'
      ];

      const months = [
        'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio',
        'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre',
        'Novembre', 'Dicembre'
      ];

      const year = this.selectedYear || moment().format('YYYY');

      const service_id = JSON.parse(JSON.stringify(this.services.service_id));
      const levels = this.selectedFillType;

      const levelData = {
        Regions: italianRegions,
        Months: months,
        Year: [year]
      };

      // Funzione ricorsiva per costruire la gerarchia con sort_id progressivo
      function createHierarchy(levelIndex, parentId = '', existingChildren = []) {
        if (levelIndex >= levels.length) {
          return [];
        }

        const levelType = levels[levelIndex];
        const items = levelData[levelType] || [];

        // Trova il valore massimo di sort_id già esistente
        let currentSortId = existingChildren.length > 0
          ? Math.max(...existingChildren.map(child => child.sort_id), 0)
          : 0;

        return items.map(name => {
          const id = uuidv4();

          return {
            service_id: service_id,
            node_name: name,
            node_description: '',
            node_id: id,
            father_id: parentId,
            children: createHierarchy(levelIndex + 1, id),
            sort_id: ++currentSortId,
            crm_folder: false
          };
        });
      }

      // Se non ci sono elementi, crea la gerarchia da zero
      if (this.services.hierarchyItems.length === 0) {
        this.services.hierarchyItems = createHierarchy(0);
      } else {
        // Usa il primo livello esistente come base per aggiungere nuovi livelli
        this.services.hierarchyItems.forEach(parentNode => {
          if (!parentNode.children) {
            parentNode.children = [];
          }

          const newLevels = createHierarchy(0, parentNode.node_id, parentNode.children);
          parentNode.children.push(...newLevels);
        });
      }

      this.services.showCreateFather = false;
      this.$emit('set-auto-fill');
    }
  }
};
</script>
